import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1047.000000 782.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,782.000000) scale(0.100000,-0.100000)">
					<path d="M5220 6199 c-301 -27 -612 -120 -888 -266 -209 -112 -381 -241 -567
-427 -413 -414 -644 -934 -681 -1533 l-7 -113 160 0 159 0 12 143 c41 520 254
968 628 1327 276 264 639 453 999 520 33 6 70 13 83 16 22 4 22 4 22 -129 l0
-132 -82 -17 c-244 -51 -541 -191 -725 -343 -384 -317 -609 -728 -663 -1210
-5 -49 -10 -109 -10 -132 l0 -43 449 0 448 0 7 73 c15 149 58 274 140 400 125
193 328 332 553 377 55 11 117 20 137 20 l36 0 0 160 0 160 -32 0 c-137 -2
-321 -47 -478 -118 -47 -21 -186 -107 -224 -138 -181 -149 -324 -354 -395
-567 l-27 -80 -130 1 -129 2 2 26 c4 47 63 217 110 314 223 461 660 763 1178
812 l125 12 0 448 0 448 -57 -1 c-32 -1 -101 -6 -153 -10z"/>
					<path d="M5721 5444 c1 -149 1 -155 22 -164 12 -4 69 -23 127 -40 208 -64 420
-192 579 -350 200 -199 328 -425 398 -707 25 -100 27 -124 28 -318 0 -234 -9
-293 -71 -477 -69 -206 -172 -373 -329 -537 -194 -202 -410 -328 -691 -402
-105 -27 -140 -32 -299 -36 -215 -7 -326 8 -507 68 -383 124 -696 404 -866
772 -27 57 -52 127 -96 272 l-13 40 -156 3 -157 3 0 -23 c0 -13 11 -66 24
-118 69 -269 186 -497 366 -713 251 -300 604 -511 993 -593 151 -32 413 -43
564 -25 259 32 508 118 709 243 34 21 69 38 79 38 10 0 48 -8 84 -19 36 -10
113 -31 171 -46 58 -15 178 -48 268 -72 90 -25 166 -42 169 -39 3 3 -18 95
-47 203 -29 109 -70 263 -91 343 -22 79 -39 152 -39 161 0 9 20 52 45 95 94
167 181 437 205 634 5 46 10 148 10 225 0 806 -551 1510 -1340 1711 -154 39
-141 51 -139 -132z"/>
					<path d="M4641 3354 c-76 -33 -129 -126 -117 -208 8 -53 37 -98 89 -140 33
-26 45 -30 105 -30 60 -1 72 2 114 31 64 45 92 104 86 183 -5 69 -39 120 -104
157 -46 27 -122 30 -173 7z"/>
					<path d="M5330 3344 c-63 -35 -93 -84 -98 -159 -5 -80 25 -138 92 -179 121
-76 280 -3 301 138 8 50 -16 126 -52 166 -55 63 -165 78 -243 34z"/>
					<path d="M6042 3349 c-110 -55 -143 -193 -69 -291 46 -60 90 -83 157 -82 133
1 221 112 196 247 -8 43 -68 113 -113 132 -51 21 -120 19 -171 -6z"/>
					<path d="M6096 1442 c-4 -3 -6 -215 -6 -472 l0 -467 23 -34 c50 -74 113 -98
280 -106 l117 -6 0 112 0 111 -73 0 c-122 0 -112 -40 -115 450 l-3 421 -109
-2 c-60 -1 -111 -4 -114 -7z"/>
					<path d="M8425 1438 c-3 -7 -4 -251 -3 -543 l3 -530 112 -3 c102 -2 112 -1
118 17 7 23 -10 102 -30 142 -14 26 -14 29 4 39 23 12 24 11 45 -62 17 -60 45
-91 120 -131 35 -19 57 -22 141 -22 87 0 108 4 156 27 107 49 174 131 204 248
20 76 19 131 -3 211 -34 123 -105 212 -211 265 -48 25 -68 29 -141 29 -73 0
-93 -4 -142 -28 -72 -35 -112 -81 -129 -146 -11 -43 -16 -51 -34 -49 -23 4
-23 14 4 88 18 48 20 80 21 251 0 171 -2 198 -16 203 -31 12 -214 7 -219 -6z
m555 -543 c59 -31 93 -89 94 -165 1 -93 -56 -162 -148 -175 -156 -24 -262 127
-189 270 43 82 155 115 243 70z"/>
					<path d="M1020 885 l0 -525 120 0 120 0 0 398 c-1 311 -4 410 -15 457 -20 81
-19 95 4 95 16 0 21 -9 25 -42 17 -141 31 -199 101 -443 42 -148 90 -314 107
-367 l29 -98 149 0 148 0 16 48 c33 106 189 662 202 722 7 36 14 89 14 118 0
38 4 54 16 59 31 11 35 -12 18 -87 -14 -61 -17 -139 -18 -467 l-1 -393 123 0
122 0 0 525 0 525 -199 0 -198 0 -22 -67 c-11 -38 -59 -185 -106 -328 -77
-232 -87 -271 -92 -357 -5 -84 -9 -98 -23 -98 -15 0 -18 14 -23 98 -4 89 -13
122 -102 392 -53 162 -101 310 -106 328 l-11 32 -199 0 -199 0 0 -525z"/>
					<path d="M2674 1110 c-102 -36 -169 -97 -217 -200 -28 -59 -31 -75 -31 -165
-1 -141 37 -231 134 -319 69 -62 120 -80 230 -81 108 0 147 14 210 74 30 28
40 49 50 94 9 43 16 57 29 57 29 0 30 -9 10 -67 -10 -31 -19 -76 -19 -100 l0
-43 115 0 115 0 0 375 0 375 -115 0 -115 0 0 -45 c0 -24 9 -68 20 -96 17 -42
18 -54 8 -60 -27 -17 -36 -8 -47 51 -10 51 -18 64 -58 100 -78 69 -209 89
-319 50z m231 -219 c57 -32 87 -128 64 -208 -27 -97 -123 -153 -204 -119 -117
49 -149 226 -57 309 51 46 133 53 197 18z"/>
					<path d="M3895 1116 c-69 -14 -130 -43 -162 -78 -12 -12 -38 -56 -58 -96 -31
-64 -38 -71 -54 -62 -18 9 -18 13 10 81 16 39 32 89 35 110 l7 39 -117 0 -116
0 0 -375 0 -375 115 0 115 0 0 151 c0 82 5 170 11 194 14 54 65 115 124 148
50 29 154 57 207 57 67 0 68 1 68 116 l0 104 -62 -1 c-35 -1 -90 -6 -123 -13z"/>
					<path d="M5405 1116 c-81 -20 -165 -66 -225 -126 -142 -138 -142 -360 -2 -506
139 -144 444 -190 627 -97 57 30 126 93 153 142 l23 41 -134 0 c-104 0 -141
-4 -166 -16 -72 -36 -208 -28 -263 16 -25 19 -64 87 -55 96 3 3 147 5 321 4
l316 0 0 48 c0 239 -197 414 -465 411 -44 0 -102 -6 -130 -13z m231 -176 c46
-23 71 -54 81 -100 l6 -30 -182 0 c-99 0 -181 2 -181 5 0 2 7 23 16 45 34 84
167 125 260 80z"/>
					<path d="M6854 1115 c-137 -30 -214 -104 -214 -205 0 -113 85 -167 354 -225
146 -31 171 -42 171 -75 0 -34 -42 -52 -121 -52 -82 1 -127 16 -158 53 -25 29
-25 29 -135 29 l-111 0 0 -32 c0 -47 45 -137 88 -176 73 -67 186 -98 329 -90
107 6 175 28 239 77 72 54 107 120 108 202 1 55 -3 72 -26 107 -50 75 -141
112 -342 138 -138 18 -169 27 -164 51 4 22 37 31 113 32 76 1 131 -14 162 -45
22 -22 31 -24 133 -24 l110 0 0 25 c0 56 -67 144 -135 178 -91 46 -272 60
-401 32z"/>
					<path d="M4130 1106 c0 -6 285 -646 316 -708 l18 -38 118 0 c75 0 118 4 118
10 0 6 30 77 66 158 36 81 80 181 96 222 17 41 34 80 38 85 6 9 31 64 106 243
l14 32 -129 0 -129 0 -10 -27 c-6 -16 -41 -104 -78 -198 -64 -163 -67 -174
-72 -272 -4 -99 -5 -103 -27 -103 -21 0 -23 4 -27 98 -4 74 -11 113 -33 167
-15 39 -50 129 -79 200 l-52 130 -127 3 c-70 1 -127 0 -127 -2z"/>
					<path d="M7492 823 c4 -318 6 -328 69 -391 18 -19 56 -46 84 -60 46 -24 60
-27 165 -27 159 0 198 22 241 136 17 46 24 56 40 52 24 -7 24 -14 0 -61 -11
-21 -22 -55 -26 -75 l-7 -37 116 0 c112 0 116 1 121 22 3 13 4 181 3 373 l-3
350 -117 3 -117 3 -3 -239 -3 -239 -27 -35 c-16 -21 -43 -42 -64 -48 -51 -17
-134 -8 -171 18 -57 41 -64 70 -70 318 l-6 224 -114 0 -115 0 4 -287z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
